import React from 'react'
import {Link} from 'gatsby'
import classNames from 'classnames'

const offerList = [
  {
    title: 'No website? No problem.',
    description: 'Billsby Pages helps you to create your own custom landing page to start selling subscriptions using Billsby, with no need to setup anything new, or have any experience of coding or development.',
    btnText: 'Create your Billsby Page',
    link: 'https://pages.billsby.com/',
    imgSrc: require("../images/labs-img.png")
  },
  {
    title: 'Export and interpret your data',
    description: <>Billsby Invoice Insights helps you to export your invoice data into Excel or a Google Spreadsheet, which you can analyze. <br /> <br /> We even automatically add handy additional context - like whether you’re dealing with a first time or recurring invoice.</>,
    btnText: 'Download invoices',
    link: 'https://labs.billsby.com/invoice-insights'
  },
  {
    title: 'See customer data elsewhere',
    description: <>Sometimes it’s helpful to access your customer information in another tool - like Google Sheets, Excel or a CRM. <br /> <br /> This labs project exports your customer data - either everything or a specific date range - so that you can use it on your other tools.</>,
    btnText: 'Export customers',
    link: 'https://labs.billsby.com/customer-export'
  },
  {
    title: 'Analyse your subscription data',
    description: <>Often it’s useful to export your subscription data in another tool - like Google Sheets, Excel or a CRM. <br /> <br /> This labs project exports your subscription data - either everything or a specific date range - so that you can use it elsewhere.</>,
    btnText: 'Export subscriptions',
    
    link: 'https://labs.billsby.com/subscription-export'
  },
  {
    title: 'Import allowance usage',
    description: <>Our counters API makes it really easy for customers to update their end users usage in real time. But what happens if your process doesn’t support this, and you need to upload the data as a .CSV instead? <br /> <br /> Our new import tool makes it easy - automatically converting your .CSV into the necessary API calls and processing them in bulk so you don’t have to.</>,
    btnText: 'Coming soon',
    disabled: true
  },
  {
    title: 'Membership cards made easy',
    description: <>If you need physical or digital membership cards, our new membership cards platform makes it easy. <br /> <br /> Automatically generate membership cards when customers sign up, collect their photo to validate their identity and re-issue membership cards on-the-fly.</>,
    btnText: 'Coming 2021',
    disabled: true
  }
]

const Offers = () => {
  return (
    <div className="section-offers">
      <div className="container">
        <div className="offers-wrapper">
          {offerList.map((list, i) => {
            return (
              <div key={i} className={classNames('offer-box', {'disabled': list.disabled === true})}>
                <div className="description-holder">
                  <p className="title">{list.title}</p>
                  <p className="description">{list.description}</p>
                </div>
                {list.imgSrc && (
                  <>
                    <img src={list.imgSrc} className="img-billsbypage" alt="Billsby page" />
                    <img src={require("../images/labs-bg.svg")} className="img-pattern" alt="Pattern" />
                  </>
                )}
                <Link to={list.link} target="_blank" className="btn btn-orange">{list.btnText}</Link>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default Offers