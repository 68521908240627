import React from 'react'
import ScheduleADemoButton from "../components/schedule-a-demo-button"

const LabsBanner = ({signUpUrlAlt}) => {
  const getSignUpUrl = () => {
    return signUpUrlAlt
      ? signUpUrlAlt
      : "https://app.billsby.com/registration"
  }

  return (
    <div className="section-labs-banner">
      <div className="container">
        <div className="content-wrapper">
          <div className="cta-wrapper">
            <h1 className="heading">Try things. Make things. Break things.</h1>
            <p className="text">When you choose Billsby, you're choosing a subscription billing platform that's always innovating, creating new tools and trying new things. This passion for experimentation means we'll always keep you ahead of the curve.</p>
            <div className="btn-wrapper">
              <a
                href={getSignUpUrl()}
                rel="noopener noreferrer"
                target="_blank"
                className="btn-black"
              >
                Sign up today
              </a>
              <ScheduleADemoButton />
            </div>
          </div>
          <div className="img-wrapper">
            <img src={require("../images/labs-banner.svg")} alt="roadmap" />
          </div>
        </div>
      </div>
    </div>
  )
}

export default LabsBanner
