import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'
import LabsBanner from "../components/labs-banner"
import Offers from "../components/offers"

const Labs = () => {
  return (
    <Layout isLabsPage={true} footerLayout4>
      <SEO 
        title="Labs" 
        description="Billsby Labs page." 
        url="https://www.billsby.com/labs"
      />
      <LabsBanner />
      <Offers />
    </Layout>
  )
}

export default Labs
